import React from 'react'
import './MainInvite2.scss'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import AddToCalendar from 'react-add-to-calendar';
import Modal from 'react-responsive-modal'
import axios from 'axios'
import {toast} from 'react-toastify';
import CustomInput from '../../../components/custom/CustomInput';
import {GiChurch, GiFamilyHouse} from 'react-icons/gi';
import {ImLocation} from 'react-icons/im';
import {IoMdRestaurant} from 'react-icons/io'
import _ from "lodash";
import {Button, Icon} from 'rsuite';
import {CustomQuestions, getUrlFromImage, PlayVideo} from '../../../components/Helper'
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Loader from "../../../components/Loader";
import moment from "moment-timezone";
import {
    getAudioLink,
    getWeddingFirstDate,
    isJsonString,
    translateInviteTexts
} from "../../../functions";
import {openMaps, openWaze} from "../../../components/InviteTemplatesFunctions";
import {SiGooglemaps, SiWaze} from "react-icons/si";
import GoogleWebLoginButton from "../../../components/GoogleLogin/GoogleWebLoginButton";
import {injectIntl} from 'react-intl';
import InviteTemplateRenderLazy from "../../../components/inviteTemplates/InviteTemplateRenderLazy";
import IntroAnimationRenderComp
    from "../../../components/inviteTemplates/components/IntroAnimationRenderComp/IntroAnimationRenderComp";
import {PlayAudio} from "../../components/Helper";
import IntroScreenTemplateRenderLazy
    from "../../../components/inviteTemplates/IntroScreen/IntroScreenTemplateRenderLazy";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";

gsap.registerPlugin(useGSAP);

class MainInvite2 extends React.Component {

    state = {
        event: {
            title: 'Nunta Alexandra si Bogdan Popescu',
            description: 'Avem deosebita placere de a va invita la nunta noastra',
            location: 'Bucharest, RO',
            startTime: '2016-09-16T20:15:00-04:00',
            endTime: '2016-09-16T21:45:00-04:00'
        },
        eventType: "",
        inviteType: "",
        inviteContents: {image: null},
        current_event: [],
        locations: [],
        loading: true,

        topRef: React.createRef(),
        formRef: React.createRef(),
        answer: "",
        nume: "",
        prenume: "",
        insotit: false,
        nume_insotitor: "",
        cuCopii: false,
        nr_copii: 1,
        kidsNumberOptions: ["1", "2", "3", "4", "5"],
        customQuestions: [],
        shouldUseCustomQuestions: true,
        answers: [],
        isUsingAudio: false,
        serverAudioUrl: null,

        email: '',
        phone: '',

        showModal: false,
        showOptionalModal: false,
        showIntroAnimation: false,
        autoPlayIntroAnimation: false,
        showIntroScreen: false,
        animationState: null
    }


    isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

    onAppMessage = async (message) => {
        let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

        if (data) {
            // eslint-disable-next-line
            switch (data.action) {
                case 'signInWithApple':
                    this.appleSignIn(data.firstName, data.lastName, data.email, data.accessToken)
                    break
                case 'signInWithFacebook':
                    this.facebookSignIn({accessToken: data.accessToken})
                    break
                case 'signInWithGoogle':
                    this.googleSignIn({tokenId: data.accessToken})
                    break
            }
        }
    }

    componentDidMount = async () => {


        [window, document].forEach(
            el => el.addEventListener('message', this.onAppMessage))
        //window.addEventListener("message", this.onAppMessage)

        document.body.classList.add('body-scroll')

        var newEvent = {}

        let event_info = []

        if (localStorage.getItem('jwt')) {
            try {
                event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id);

            } catch (err) {
                console.log(err);
                toast.error("Cod Eveniment gresit")
                return
            }

            if (this.props.match.params.secondaryInviteId) {
                event_info.data.invite = event_info.data.additional_invites.find((item) => item.id === parseInt(this.props.match.params.secondaryInviteId + ''))
            }

            let audioLink = await getAudioLink(event_info.data.invite);

            if (audioLink && audioLink !== '') {
                this.setState({
                    serverAudioUrl: audioLink,
                    isUsingAudio: true
                })
            }

            newEvent.description = event_info.data.description
            newEvent.location = ""

            newEvent.title = "Nunta " + event_info.data.prenumeMire + " și " + event_info.data.prenumeMireasa
            if (event_info.data.eventType === "Botez") {
                newEvent.title = "Botez " + event_info.data.prenumeMire
            }
            newEvent.startTime = event_info.data.dataEvent
            newEvent.endTime = event_info.data.dataEvent

            let customDate = getWeddingFirstDate(event_info.data)

            if (customDate) {
                newEvent.startTime = customDate.date.toISOString()
                newEvent.endTime = customDate.date.toISOString()
            }

            let questions = _.cloneDeep(event_info.data.questions)

            if (questions === null) {
                questions = []
            }

            let answers = []

            for (let i = 0; i < questions.length; i++) {
                answers.push("")

                let newOptions = []

                for (let j = 0; j < questions[i].options.length; j++) {
                    newOptions.push({
                        label: questions[i].options[j],
                        value: questions[i].options[j]
                    })
                }

                questions[i].options = newOptions
            }
            this.setState({
                inviteType: event_info.data.invite.inviteType,
                inviteContents: event_info.data.invite,
                locations: event_info.data.locatii,
                event: newEvent,
                current_event: event_info.data,
                eventType: event_info.data.eventType,
                customQuestions: questions,
                shouldUseCustomQuestions: event_info.data.useCustomQuestions,
                answers: answers,
                loading: false,
                showIntroAnimation: event_info.data.invite.templateDetails && event_info.data.invite.templateDetails.introAnimationId,
                showIntroScreen: event_info.data.invite.templateDetails && event_info.data.invite.templateDetails.introScreenId
            })
        }

    }


    componentWillUnmount() {
        window.removeEventListener("message", this.onAppMessage)
        document.removeEventListener("message", this.onAppMessage)
        document.body.classList.remove('body-scroll')
        gsap.globalTimeline.clear()
    }

    loginGoogle = async () => {
        if (window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('loginGoogle')
                .then(token => {
                    if (token === "NULL" || token === "ERROR") {
                        if (token === "ERROR") {
                            toast.error("Eroare la conectare, va rog reincercati.")
                        }
                    } else {
                        this.googleSignIn({tokenId: token})
                    }
                })
        }

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'signInWithGoogle'
            }))
        }
    }

    googleSignIn = (response) => {

        if (!response.tokenId) {
            return;
        }

        let tokenId = response.tokenId
        //tokenId = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE4MmU0NTBhMzVhMjA4MWZhYTFkOWFlMWQyZDc1YTBmMjNkOTFkZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE3MDU1NjE0NDAyMTA4NTczOTI3IiwiZW1haWwiOiJ2bGFkLml2YW5jaXUyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQVFtbllaazQ5czRoZk5VWHV1dW53dyIsIm5hbWUiOiJWbGFkIEl2YW5jaXUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeEJpMm9yVktacnlrY1NwRHJnRjh2T3lUM0JVLXg1OTdsV2xBM2g0UT1zOTYtYyIsImdpdmVuX25hbWUiOiJWbGFkIiwiZmFtaWx5X25hbWUiOiJJdmFuY2l1IiwibG9jYWxlIjoicm8iLCJpYXQiOjE2NDQ0MDYzNjgsImV4cCI6MTY0NDQwOTk2OCwianRpIjoiYTA5Mjk3OTBhODliMDliMDVhZDA0MzY3NzM4YjQ0ZDIzOGM1OWU3YSJ9.GfRbRzpfxQVz5OR5T3N_1RvXljePBvrHUwhqAUetJ9_YgLZCulsiKkbuWznKlgtPIVn_6IeF_HE6jxbgpgqDg_0w5KqsKLAp18Tc0ICZqsOhOwCrQYw9USajjc4YmgFMtO_rtytDqvAx67jZFPsnnNarcVUXlni5gmg1mmsRMNw_xnaGc7w9T0wOqTz36og_z2spjxzSNrK3Gp6BE2wy5mt0KvpYFKq8nJJOJLp8FHMLKLhCKUOGiRK8bnHc_RFJ3x8WxmYwBIT9floaS-4YII5mSSN9y2XLYLVKnPa_N2mWinNNe-DsNkby1QJQ8lw2mW5gGoGTuwCFJiSkBCdTMA"

        let obj = {
            tokenId: tokenId
        }

        this.setState({
            loading: true
        })

        axios.post(process.env.REACT_APP_DOMAIN + 'login/social/google', obj)
            .then(async (response) => {
                if (response.data.user.confirmed) {
                    toast.success("Logare cu succes!")

                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('id', response.data.user.id);
                    //localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")


                    var eventId = localStorage.getItem("eventId")

                    let responseId = localStorage.getItem("responseId")

                    if (responseId !== null) {
                        let data = {
                            responseId: responseId
                        }

                        let auth = {
                            headers: {
                                'Authorization': 'Bearer ' + response.data.jwt
                            }
                        }

                        await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
                    }

                    if (eventId !== null) {
                        if (responseId !== null) {
                            localStorage.removeItem("responseId")
                            this.props.history.push('/eventPageW/' + eventId)
                        } else {
                            this.props.history.push('/eventPage/' + eventId)
                        }

                        localStorage.removeItem('eventId');
                    } else {
                        this.props.history.push('/events')
                    }
                } else {
                    toast.error("Va rog confirmati email-ul")
                }
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Email sau parola gresita")
            })


    }

    loginFacebook = async () => {
        if (window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('loginFacebook')
                .then(token => {
                    if (token === "NULL" || token === "ERROR") {
                        if (token === "ERROR") {
                            toast.error("Eroare la conectare, va rog reincercati.")
                        }
                    } else {
                        this.facebookSignIn({accessToken: token})
                    }
                })
        }

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'signInWithFacebook'
            }))
        }
    }

    facebookSignIn = (response) => {

        if (!response.accessToken) {
            return;
        }

        let accessToken = response.accessToken

        let obj = {
            accessToken: accessToken
        }

        this.setState({
            loading: true
        })

        axios.post(process.env.REACT_APP_DOMAIN + 'login/social/facebook', obj)
            .then(async (response) => {
                if (response.data.user.confirmed) {
                    toast.success("Logare cu succes!")

                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

                    var eventId = localStorage.getItem("eventId")

                    let responseId = localStorage.getItem("responseId")

                    if (responseId !== null) {
                        let data = {
                            responseId: responseId
                        }

                        let auth = {
                            headers: {
                                'Authorization': 'Bearer ' + response.data.jwt
                            }
                        }

                        await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
                    }

                    if (eventId !== null) {
                        if (responseId !== null) {
                            localStorage.removeItem("responseId")
                            this.props.history.push('/eventPageW/' + eventId)
                        } else {
                            this.props.history.push('/eventPage/' + eventId)
                        }

                        localStorage.removeItem('eventId');
                    } else {
                        this.props.history.push('/events')
                    }
                } else {
                    toast.error("Va rog confirmati email-ul")
                }
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Email sau parola gresita")
            })


    }

    appleSignIn = (firstName, lastName, email, accessToken) => {

        let obj = {
            accessToken: accessToken,
            email: email,
            first_name: firstName,
            last_name: lastName
        }

        this.setState({
            loading: true
        })

        axios.post(process.env.REACT_APP_DOMAIN + 'login/social/apple', obj)
            .then(async (response) => {
                if (response.data.user.confirmed) {
                    toast.success("Logare cu succes!")

                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

                    var eventId = localStorage.getItem("eventId")

                    let responseId = localStorage.getItem("responseId")

                    if (responseId !== null) {
                        let data = {
                            responseId: responseId
                        }

                        let auth = {
                            headers: {
                                'Authorization': 'Bearer ' + response.data.jwt
                            }
                        }

                        await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
                    }

                    if (eventId !== null) {
                        if (responseId !== null) {
                            localStorage.removeItem("responseId")
                            this.props.history.push('/eventPageW/' + eventId)
                        } else {
                            this.props.history.push('/eventPage/' + eventId)
                        }

                        localStorage.removeItem('eventId');
                    } else {
                        this.props.history.push('/events')
                    }
                } else {
                    toast.error("Va rog confirmati email-ul")
                }
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Email sau parola gresita")
            })


    }

    loginApple = async () => {
        if (window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('loginApple')
                .then(data => {
                    let response = data.split('|')
                    this.appleSignIn(response[2], response['1'], response['3'], response['0'])
                })
        }

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'signInWithApple'
            }))
        }
    }

    setAnswer = (ans) => {
        this.setState({
            answer: ans
        })

        this.timer = setTimeout(
            () => this.state.topRef.current.scrollIntoView(),
            100
        )
    }

    setter = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeAnswers = (newAnswers) => {
        this.setState({
            answers: newAnswers
        })
    }

    sendAnswer = () => {
        toast.info('Trimiterea răspunsurilor este dezactivată în modul de previzualizare! Vă rugăm folosiți butonul "Înapoi" pentru a vă întoarce la ecranul anterior.')
    }

    moveToEvent = () => {
        this.props.history.push('/eventPageW/' + this.props.match.params.id)
    }

    saveGuestData = async () => {
        let data = {
            responseId: this.state.responseId,
            email: this.state.email,
            phone: this.state.phone
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'responses/saveGuestData/' + this.state.responseId, data)

        this.props.history.push("/events")
    }
    scrollContainerBottom = () => {
        if (this.state.formRef && this.state.formRef.current) {
            setTimeout(
                () => this.state.formRef.current.scrollIntoView(),
                10
            )
        }
    }
    scrollToLocations = () => {
        setTimeout(
            () => {
                let element = document.getElementsByClassName("locations-inner")[0];
                if (element) {
                    element.scrollIntoView()
                }
            },
            10
        )
    }

    render() {
        if (this.state.loading === true)
            return !this.props.disableAction ?
                <div className="Loading-Screen">
                    <Loader/>
                </div>
                :
                <Loader/>

        let items = [
            {google: 'Google'},
            {apple: 'Apple'},
            {outlook: 'Outlook'},
        ]

        let style = {
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: 'center',
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: 'darken',
            backgroundImage: this.state.inviteContents.image !== null ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.inviteContents.image)})` : ""
        }

        let customStyle = {
            color: this.state.inviteContents.fontColor,
            fontFamily: this.state.inviteContents.fontFamily,
            fontSize: this.state.inviteContents.fontSize
        }

        let locations = this.state.locations.map((location, index) => {
            let time = new Date(location.time)
            let day = new Date(location.time)

            if (location.day !== undefined) {
                day = new Date(location.day)
            }

            let icon = <ImLocation/>

            if (location.name === "Locatie petrecere") {
                icon = <IoMdRestaurant/>
            } else if (location.name === "Locatie biserica") {
                icon = <GiChurch/>
            } else if (location.name === "Locatie cununie") {
                icon = <GiFamilyHouse/>
            }

            return <div key={index} style={{margin: "10px 0px"}}>
                <div style={{
                    color: this.state.inviteContents.fontColor,
                    fontFamily: this.state.inviteContents.fontFamily,
                    fontSize: Number(this.state.inviteContents.fontSize.split("px")[0]) + 5 + "px"
                }}>{location.name}</div>

                {icon}

                <div style={customStyle}>{location.label ? location.label : location.address}</div>

                <div
                    style={customStyle}>{moment(day).format('DD-MM-YYYY')} {moment(time).format('HH:mm')}</div>

                {
                    location.pinx && location.piny ?
                        <div className={"buttons"}>
                            <div className={"waze"}
                                 onClick={() => openWaze(location.pinx, location.piny, 0)}><SiWaze
                                style={{marginRight: "5px"}}/> Waze
                            </div>
                            <div className={"maps"}
                                 onClick={() => openMaps(location.pinx, location.piny, 0)}>
                                <SiGooglemaps style={{marginRight: "5px"}}/> Google Maps
                            </div>
                        </div>
                        : null
                }
            </div>
        })

        let invite = <div></div>

        if (this.state.inviteType === "Personal") {
            invite = <div style={{marginTop: "20px"}}>
                <img
                    src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.inviteContents.image)}`}
                    alt={"Invitatie personala"} style={{width: "90%",}}/>

                {this.state.isUsingAudio && <PlayAudio
                    link={this.state.serverAudioUrl}/>}

                {!this.props.disableAction && !this.state.inviteContents.hideConfirmForm &&
                    <div className="Answer" ref={this.state.formRef}>
                        <p style={{fontSize: "20px"}}>{translateInviteTexts('Doriți să participați?', this.state.current_event.invite.language)}</p>

                        <div className="Options">
                            <div className="Yes"
                                 onClick={() => this.setAnswer("yes")}>{translateInviteTexts('Particip', this.state.current_event.invite.language)}</div>
                            <div className="No"
                                 onClick={() => this.setAnswer("no")}>{translateInviteTexts('Nu Particip', this.state.current_event.invite.language)}</div>


                            {
                                this.state.current_event.invite.hideDontKnowButton ?
                                    null
                                    :
                                    <div className="NotSure"
                                         onClick={() => this.setAnswer("don't know")}>{translateInviteTexts('Nu știu', this.state.current_event.invite.language)}</div>
                            }
                        </div>
                    </div>
                }
            </div>
        }

        if (this.state.inviteType === "Video") {
            let linkParts = this.state.inviteContents.linkVideo.split("watch?v=")
            let link = ""
            if (linkParts.length === 1) {
                link = linkParts[0]
            } else {
                link = linkParts[0] + "embed/" + linkParts[1]
            }

            let videoId = this.state.inviteContents.linkVideo.split("watch?v=")[1].split("&")[0]

            invite = <div className="MainInvite-contents old" style={customStyle}>
                {/* <iframe src={link.split("&")[0]}
					frameBorder='0'
					allow='autoplay; encrypted-media'
					allowFullScreen={true}
					title='video'
					style={{ width: "80vw", height: "500px", margin: "20px auto" }}
				/> */}
                <PlayVideo videoId={videoId} link={link.split("&")[0]}
                           style={{width: "80vw", height: "500px", margin: "20px auto"}}/>

                {!this.props.disableAction && !this.state.inviteContents.hideConfirmForm &&
                    <div className="Answer" ref={this.state.formRef}>
                        <p style={{fontSize: "20px"}}>{translateInviteTexts('Doriți să participați?', this.state.current_event.invite.language)}</p>

                        <div className="Options">
                            <div className="Yes"
                                 onClick={() => this.setAnswer("yes")}>{translateInviteTexts('Particip', this.state.current_event.invite.language)}</div>
                            <div className="No"
                                 onClick={() => this.setAnswer("no")}>{translateInviteTexts('Nu Particip', this.state.current_event.invite.language)}</div>


                            {
                                this.state.current_event.invite.hideDontKnowButton ?
                                    null
                                    :
                                    <div className="NotSure"
                                         onClick={() => this.setAnswer("don't know")}>{translateInviteTexts('Nu știu', this.state.current_event.invite.language)}</div>
                            }
                        </div>
                    </div>
                }
            </div>
        }

        if (this.state.inviteType === "Custom") {
            let has_both_parents_mire = this.state.inviteContents.parinti[1].tataMire !== "" && this.state.inviteContents.parinti[0].mamaMire !== ""
            let has_at_least_one_parent_mire = this.state.inviteContents.parinti[1].tataMire !== "" || this.state.inviteContents.parinti[0].mamaMire !== ""

            let has_both_parents_mireasa = this.state.inviteContents.parinti[3].tataMireasa !== "" && this.state.inviteContents.parinti[4].mamaMireasa !== ""
            let has_at_least_one_parent_mireasa = this.state.inviteContents.parinti[3].tataMireasa !== "" || this.state.inviteContents.parinti[4].mamaMireasa !== ""

            if (this.state.eventType === "Nunta") {
                invite = <div className="MainInvite-contents old" style={{...customStyle}}>
                    <h3 style={{
                        marginBottom: "40px",
                        marginTop: '40px'
                    }}>{this.state.current_event.prenumeMire} și {this.state.current_event.prenumeMireasa}</h3>

                    <p>Spre bucuria părinților,</p>
                    <div style={{
                        display: "flex",
                        justifyContent: 'space-around',
                        marginTop: "20px"
                    }}>
                        {has_at_least_one_parent_mire && <div>
                            <div>{this.state.inviteContents.parinti[1].tataMire} {has_both_parents_mire ? "și" : ""} {this.state.inviteContents.parinti[0].mamaMire}</div>

                            <div>{this.state.inviteContents.parinti[4].numeParintiMire}</div>
                        </div>}

                        {has_at_least_one_parent_mireasa && <div>
                            <div>{this.state.inviteContents.parinti[3].tataMireasa} {has_both_parents_mireasa ? "și" : ""} {this.state.inviteContents.parinti[2].mamaMireasa}</div>

                            <div>{this.state.inviteContents.parinti[5].numeParintiMireasa}</div>
                        </div>}
                    </div>

                    {this.state.inviteContents.nasi.length > 0 &&
                        <p style={{marginBottom: "20px"}}>și a nașilor,</p>}
                    {this.state.inviteContents.nasi.length > 0 &&
                        <div>{this.state.inviteContents.nasi[0].numeNas} și {this.state.inviteContents.nasi[0].numeNasa} {this.state.inviteContents.nasi[0].numeFamilie}</div>}

                    <p style={{margin: "20px 10px"}}>{this.state.inviteContents.description}</p>

                    {this.state.isUsingAudio && <PlayAudio
                        link={'' + this.state.serverAudioUrl + ''}/>}

                    <div className="locations">
                        {locations}
                    </div>

                    {!window.flutter_inappwebview && !window.alityAppView &&
                        <div className={"calendar-container"}>
                            <div className={"calendar-button"}>
                                <div className="calendar">
                                    <AddToCalendar style={{backgroundColor: "black"}}
                                                   event={this.state.event}
                                                   listItems={items}
                                                   buttonLabel="Adaugă evenimentul în calendar"/>
                                </div>
                            </div>
                        </div>}

                    {!this.props.disableAction && !this.state.inviteContents.hideConfirmForm &&
                        <div className="Answer" ref={this.state.formRef}>
                            <p style={{fontSize: "20px"}}>{translateInviteTexts('Doriți să participați?', this.state.current_event.invite.language)}</p>

                            <div className="Options">
                                <div className="Yes"
                                     onClick={() => this.setAnswer("yes")}>{translateInviteTexts('Particip', this.state.current_event.invite.language)}</div>
                                <div className="No"
                                     onClick={() => this.setAnswer("no")}>{translateInviteTexts('Nu Particip', this.state.current_event.invite.language)}</div>

                                {
                                    this.state.current_event.invite.hideDontKnowButton ?
                                        null
                                        :
                                        <div className="NotSure"
                                             onClick={() => this.setAnswer("don't know")}>{translateInviteTexts('Nu știu', this.state.current_event.invite.language)}</div>
                                }
                            </div>
                        </div>
                    }


                </div>
            }

            if (this.state.eventType === "Botez") {
                invite = <div className="MainInvite-contents old" style={customStyle}>
                    <h3 style={{marginBottom: "10px", marginTop: '40px'}}>Salut, ma
                        numesc {this.state.current_event.prenumeMire}</h3>

                    <div style={{
                        display: "flex",
                        justifyContent: 'space-around',
                        margin: "20px 0px"
                    }}>
                        <p>Alături de parinții
                            mei, {this.state.inviteContents.parinti[1].tataMire} și {this.state.inviteContents.parinti[0].mamaMire}</p>
                    </div>

                    {this.state.inviteContents.nasi.length > 0 && <div>și
                        nașii, {this.state.inviteContents.nasi[0].numeNas} și {this.state.inviteContents.nasi[0].numeNasa} {this.state.inviteContents.nasi[0].numeFamilie}</div>}

                    <p style={{margin: "20px 10px"}}>{this.state.inviteContents.description}</p>
                    {this.state.isUsingAudio && <PlayAudio link={this.state.serverAudioUrl}/>}


                    <div className="locations">
                        {locations}
                    </div>

                    {!window.flutter_inappwebview && !window.alityAppView &&
                        <div className={"calendar-container"}>
                            <div className={"calendar-button"}>
                                <div className="calendar">
                                    <AddToCalendar style={{backgroundColor: "black"}}
                                                   event={this.state.event}
                                                   listItems={items}
                                                   buttonLabel="Adauga evenimentul in calendar"/>
                                </div>
                            </div>
                        </div>}

                    {!this.props.disableAction && !this.state.inviteContents.hideConfirmForm &&
                        <div className="Answer" ref={this.state.formRef}>
                            <p style={{fontSize: "20px"}}>{translateInviteTexts('Doriți să participați?', this.state.current_event.invite.language)}</p>

                            <div className="Options">
                                <div className="Yes"
                                     onClick={() => this.setAnswer("yes")}>{translateInviteTexts('Particip', this.state.current_event.invite.language)}</div>
                                <div className="No"
                                     onClick={() => this.setAnswer("no")}>{translateInviteTexts('Nu Particip', this.state.current_event.invite.language)}</div>

                                {
                                    this.state.current_event.invite.hideDontKnowButton ?
                                        null
                                        :
                                        <div className="NotSure"
                                             onClick={() => this.setAnswer("don't know")}>{translateInviteTexts('Nu știu', this.state.current_event.invite.language)}</div>
                                }
                            </div>
                        </div>
                    }
                </div>
            }

            if (this.state.eventType === "Petrecere") {
                invite = <div className="MainInvite-contents old" style={customStyle}>
                    <h3 style={{marginBottom: "10px", marginTop: '40px'}}>Salut, sunteti invitati la
                        petrecerea {this.state.current_event.prenumeMire}</h3>

                    <p style={{margin: "20px 10px"}}>{this.state.inviteContents.description}</p>


                    {this.state.isUsingAudio && <PlayAudio

                        link={this.state.serverAudioUrl}/>}

                    <div className="locations">
                        {locations}
                    </div>

                    {!window.flutter_inappwebview && !window.alityAppView &&
                        <div className={"calendar-container"}>
                            <div className={"calendar-button"}>
                                <div className="calendar">
                                    <AddToCalendar style={{backgroundColor: "black"}}
                                                   event={this.state.event}
                                                   listItems={items}
                                                   buttonLabel="Adauga evenimentul in calendar"/>
                                </div>
                            </div>
                        </div>}

                    {!this.props.disableAction && !this.state.inviteContents.hideConfirmForm &&
                        <div className="Answer" ref={this.state.formRef}>
                            <p style={{fontSize: "20px"}}>{translateInviteTexts('Doriți să participați?', this.state.current_event.invite.language)}</p>

                            <div className="Options">
                                <div className="Yes"
                                     onClick={() => this.setAnswer("yes")}>{translateInviteTexts('Particip', this.state.current_event.invite.language)}</div>
                                <div className="No"
                                     onClick={() => this.setAnswer("no")}>{translateInviteTexts('Nu Particip', this.state.current_event.invite.language)}</div>

                                {
                                    this.state.current_event.invite.hideDontKnowButton ?
                                        null
                                        :
                                        <div className="NotSure"
                                             onClick={() => this.setAnswer("don't know")}>{translateInviteTexts('Nu știu', this.state.current_event.invite.language)}</div>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        }


        if (this.state.inviteContents && this.state.inviteContents.templateDetails && this.state.inviteContents.templateDetails.id) {
            return (<div>


                                {this.state.inviteContents.templateDetails.introScreenId && this.state.inviteContents.templateDetails.introScreenId > 0 ?
                                    <IntroScreenTemplateRenderLazy
                                        showIntroScreen={this.state.showIntroScreen}
                                        templateDetails={this.state.inviteContents.templateDetails}
                                        weddingData={this.state.current_event}
                                        showIntroAnimation={() => {
                                            if (this.state.showIntroAnimation !== true && this.state.current_event.invite.templateDetails && this.state.current_event.invite.templateDetails.introAnimationId)
                                                this.setState({
                                                    showIntroAnimation: true
                                                })
                                        }}
                                        openInvitation={() => {
                                            this.setState({
                                                autoPlayIntroAnimation: true,
                                                showIntroScreen: false,
                                            })
                                        }}
                                        mode={0}
                                        goToAnswer={() => {
                                            this.setState({
                                                showIntroScreen: false,
                                                showIntroAnimation: false,
                                            }, () => {
                                                this.setState({
                                                    animationState: 'ended'
                                                })
                                                this.scrollContainerBottom()
                                            })
                                        }}
                                        goToLocations={() => {
                                            this.setState({
                                                showIntroScreen: false,
                                                showIntroAnimation: false,
                                            }, () => {
                                                this.setState({
                                                    animationState: 'ended'
                                                })
                                                this.scrollToLocations()
                                            })
                                        }}
                                        goToInvitation={() => {
                                            this.setState({
                                                    showIntroScreen: false,
                                                    showIntroAnimation: false,
                                                }, () =>
                                                    this.setState({
                                                        animationState: 'ended'
                                                    })
                                            )

                                        }}
                                        showResponseButton={!this.props.disableAction && !this.state.inviteContents.hideConfirmForm}
                                    /> : null}

                    <IntroAnimationRenderComp
                        show={this.state.inviteContents.templateDetails && this.state.inviteContents.templateDetails.introAnimationId && !this.state.showIntroScreen}
                        showIntroAnimation={this.state.showIntroAnimation}
                        showInvite={!(this.state.showIntroAnimation || this.state.showIntroScreen)}
                        templateDetails={this.state.inviteContents.templateDetails}
                        weddingData={this.state.current_event}
                        setShowInvite={(value) => this.setState({showIntroAnimation: !value})}
                        setAutoPlayIntroAnimation={(value) => this.setState({autoPlayIntroAnimation: value})}
                        autoPlayIntroAnimation={this.state.autoPlayIntroAnimation}
                        animationState={this.state.animationState}
                        setAnimationState={(value) => {
                            this.setState({
                                animationState: value
                            })
                        }}
                    />
                    <div>
                        <Button className="action btn-back"
                                style={{
                                    position: "absolute",
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 1000
                                }}
                                onClick={() => this.props.history.go(-1)}
                                color="red"> Înapoi </Button>
                        {!this.state.showIntroScreen && !this.state.showIntroAnimation &&
                        this.state.current_event && this.state.current_event.invite &&
                        this.state.current_event.invite.templateDetails && this.state.current_event.invite.templateDetails.introScreenId ?
                            <Button className="action btn-back inv-top-btn"
                                    style={{
                                        position: "absolute",
                                        top: '20px',
                                        left: '100px',
                                        zIndex: 1000,
                                        backgroundColor: "darkgrey"
                                    }}
                                    onClick={() => this.setState({
                                        animationState: 'ended',
                                        showIntroAnimation: false,
                                        showIntroScreen: true
                                    })}
                                    color="red"> <Icon icon="home" style={{color: "white"}}/>
                            </Button> : null}

                    </div>

                    <div className={"new-invite-container " + (this.state.showIntroAnimation || this.state.showIntroScreen ? "inviteHiddenCont" : "")}
                         style={this.state.showIntroAnimation || this.state.showIntroScreen ? {
                             //position: 'absolute',
                             //left: '-200vw'
                         } : {zIndex: 1001}}>
                        <div className={"new-invite-container-inner"}>
                            <div className={"invite-container"}>
                                <InviteTemplateRenderLazy
                                    templateDetails={this.state.inviteContents.templateDetails}
                                    weddingData={this.state.current_event}
                                    locations={this.state.current_event.locatii}
                                    audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                    image1Link={this.state.current_event.invite.image1 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image1.url}` : ''}
                                    image2Link={this.state.current_event.invite.image2 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image2.url}` : ''}
                                    image3Link={this.state.current_event.invite.image3 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image3.url}` : ''}
                                    coverImageLink={this.state.current_event.invite.cover_image ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.cover_image.url}` : ''}
                                    mode={0}
                                />

                                {!this.props.disableAction && !this.state.inviteContents.hideConfirmForm &&
                                    <div className="Answer" ref={this.state.formRef}>
                                        <p style={{fontSize: "20px"}}>{translateInviteTexts('Doriți să participați?', this.state.current_event.invite.language)}</p>

                                        <div className="Options">
                                            <div className="Yes"
                                                 onClick={() => this.setAnswer("yes")}>{translateInviteTexts('Particip', this.state.current_event.invite.language)}</div>
                                            <div className="No"
                                                 onClick={() => this.setAnswer("no")}>{translateInviteTexts('Nu Particip', this.state.current_event.invite.language)}</div>

                                            {
                                                this.state.current_event.invite.hideDontKnowButton ?
                                                    null
                                                    :
                                                    <div className="NotSure"
                                                         onClick={() => this.setAnswer("don't know")}>{translateInviteTexts('Nu știu', this.state.current_event.invite.language)}</div>
                                            }
                                        </div>
                                    </div>
                                }

                                {this.state.answer !== "" && <div className='Form'>
                                    <div ref={this.state.topRef}></div>

                                    <div style={{
                                        textAlign: "left",
                                        fontSize: "18px"
                                    }}>{translateInviteTexts('Vă rugăm introduceți următoarele informații:', this.state.current_event.invite.language)}
                                    </div>

                                    <CustomInput value={this.state.prenume}
                                                 onChange={value => this.setter("prenume", value)}
                                                 placeholder={translateInviteTexts('Prenume, Nume', this.state.current_event.invite.language)}/>

                                    {this.state.current_event.invite.hideEmailInput ? null :
                                        <CustomInput value={this.state.email}
                                                     onChange={value => this.setter("email", value)}
                                                     style={{marginTop: 0}}
                                                     placeholder={translateInviteTexts('Adresa de email', this.state.current_event.invite.language)}/>}

                                    {this.state.answer === "no" &&
                                        <p>{translateInviteTexts('Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam', this.state.current_event.invite.language)}</p>}
                                    {this.state.answer === "don't know" &&
                                        <p>{translateInviteTexts('Nu este obligatoriu să răspundeți acum, când sunteți sigur', this.state.current_event.invite.language)}</p>}

                                    {this.state.answer === "yes" && <div>
                                        <div className="data-row">
                                            <div
                                                className={!this.state.insotit ? "active-option" : "inactive-option"}
                                                onClick={_ => this.setter("insotit", false)}>
                                                {translateInviteTexts('Vin singur/ă', this.state.current_event.invite.language)}
                                            </div>

                                            <div
                                                className={this.state.insotit ? "active-option" : "inactive-option"}
                                                onClick={_ => this.setter("insotit", true)}>
                                                {translateInviteTexts('Vin insoțit/ă', this.state.current_event.invite.language)}
                                            </div>
                                        </div>
                                        {this.state.insotit && (
                                            <CustomInput value={this.state.nume_insotitor}
                                                         onChange={value => this.setter("nume_insotitor", value)}
                                                         placeholder={translateInviteTexts("Prenume, Nume Insotitor", this.state.current_event.invite.language)}/>
                                        )}

                                        {
                                            this.state.current_event.invite.hideChildrenQuestion ?
                                                null
                                                :
                                                this.state.current_event.eventType !== 'Petrecere' ?
                                                    <>
                                                        <div style={{
                                                            fontSize: "18px",
                                                            textAlign: "left"
                                                        }}>{translateInviteTexts('Veniți însoțiți de copii?', this.state.current_event.invite.language)}</div>
                                                        <div className="data-row">
                                                            <div
                                                                className={!this.state.cuCopii ? "active-option" : "inactive-option"}
                                                                onClick={_ => this.setter("cuCopii", false)}>
                                                                {translateInviteTexts('Nu', this.state.current_event.invite.language)}
                                                            </div>

                                                            <div
                                                                className={this.state.cuCopii ? "active-option" : "inactive-option"}
                                                                onClick={_ => this.setter("cuCopii", true)}>
                                                                {translateInviteTexts('Da', this.state.current_event.invite.language)}
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null
                                        }
                                        {this.state.cuCopii && (
                                            <div>
                                                <div style={{
                                                    fontSize: "18px",
                                                    textAlign: "left"
                                                }}>{translateInviteTexts('Cu câți copii veniți?', this.state.current_event.invite.language)}</div>

                                                <div className="data-row">
                                                    {this.state.kidsNumberOptions.map((nr, index) =>
                                                        <div key={index}
                                                             className={this.state.nr_copii === nr ? "active-option" : "inactive-option"}
                                                             onClick={_ => this.setter("nr_copii", nr)}>
                                                            {nr}
                                                        </div>)}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.shouldUseCustomQuestions &&
                                            <CustomQuestions
                                                questions={this.state.customQuestions}
                                                answers={this.state.answers}
                                                showTitle={true}
                                                changeAnswers={this.changeAnswers}
                                                language={this.state.current_event.invite.language}
                                                intl={this.props.intl}/>}
                                    </div>}

                                    <br/>
                                    <Button onClick={this.sendAnswer} className="add-button"
                                            style={{
                                                backgroundColor: "#00afcc",
                                                color: "white",
                                                height: '60px'
                                            }}>
                                        {translateInviteTexts('Trimite răspuns', this.state.current_event.invite.language)}
                                    </Button>

                                    <div style={{
                                        marginTop: "50px",
                                        marginBottom: "50px",
                                        fontSize: "18px"
                                    }}>
                                        {translateInviteTexts('Vă mulțumim!', this.state.current_event.invite.language)}
                                    </div>
                                </div>}
                            </div>
                        </div>


                        <Modal
                            classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-invitation',
                                closeButton: 'modal-close-button',
                            }}
                            center
                            open={this.state.showModal}
                            onClose={() => {
                            }}
                            showCloseIcon={false}
                            closeOnOverlayClick={false}
                            closeOnEsc={false}
                        >
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <p style={{marginBottom: "20px"}}> Pt a beneficia de toate
                                    serviciile Ality (profil, acces
                                    rapid
                                    la toate nunțile si evenimentele la care participați,
                                    alte
                                    informații), trebuie să aveti
                                    cont</p>

                                <div className="account-options">
                                    <Button onClick={() => {
                                        this.props.history.push("/login")
                                    }} className="add-button"
                                            style={{
                                                backgroundColor: "#00afcc",
                                                color: "white"
                                            }}>
                                        Intra in cont
                                    </Button>

                                </div>

                                <div className={"account-options social-buttons"}>
                                    <Button onClick={() => {
                                        this.props.history.push("/signup")
                                    }} className="add-button"
                                            style={{
                                                backgroundColor: "#00afcc",
                                                color: "white"
                                            }}>
                                        Creeaza cont
                                    </Button>

                                    {
                                        window.flutter_inappwebview || window.alityAppView ?

                                            <div style={{
                                                flexDirection: 'column',
                                                width: '100%'
                                            }}>
                                                <button onClick={this.loginGoogle}
                                                        className={"social-login-button social-google-login"}
                                                        style={{width: '100%'}}>
                                                    <Icon icon="google"/> Login Google
                                                </button>

                                                <button onClick={this.loginFacebook}
                                                        className={"social-login-button social-facebook-login"}
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '10px'
                                                        }}
                                                >
                                                    <Icon icon="facebook"/> Login Facebook
                                                </button>

                                                {
                                                    this.isIOS ?
                                                        <button onClick={this.loginApple}
                                                                className={"social-login-button social-apple-login"}
                                                                style={{
                                                                    width: '100%',
                                                                    marginTop: '10px'
                                                                }}
                                                        >
                                                            <Icon icon="apple"/> Login Apple
                                                        </button>
                                                        : null
                                                }
                                            </div>
                                            :
                                            <>
                                                <GoogleWebLoginButton
                                                    callbackFunction={(val) => this.googleSignIn(val)}
                                                />

                                                <FacebookLogin
                                                    appId="243653927956471"
                                                    autoLoad={false}
                                                    fields="name,email"
                                                    //onClick={(item) => this.facebookSignIn(item)}
                                                    callback={(item) => this.facebookSignIn(item)}
                                                    //cookie
                                                    //
                                                    disableMobileRedirect={true}
                                                    //isMobile={true}
                                                    redirectUri={'https://app.ality.ro'}
                                                    render={renderProps => (
                                                        <button
                                                            className={"social-login-button social-facebook-login"}
                                                            type={"button"}
                                                            onClick={renderProps.onClick}
                                                            disabled={renderProps.disabled}
                                                        ><Icon
                                                            icon="facebook"/> Autentificare
                                                            cu
                                                            Facebook</button>
                                                    )}
                                                />
                                            </>
                                    }
                                </div>


                                <Button onClick={() => {
                                    this.props.history.push("/events")
                                }} className="add-button"
                                        style={{
                                            backgroundColor: "#00afcc",
                                            color: "white"
                                        }}>
                                    Nu, mulțumesc!
                                </Button>
                            </div>
                        </Modal>

                        <Modal
                            classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-invitation',
                                closeButton: 'modal-close-button',
                            }}
                            center
                            open={this.state.showOptionalModal}
                            onClose={() => {
                            }}
                            showCloseIcon={false}
                            closeOnOverlayClick={false}
                            closeOnEsc={false}
                        >
                            Vă rugăm să completați adresa de email sau numărul de telefon.

                            <div style={{display: "flex", flexDirection: "column"}}>

                                <CustomInput value={this.state.email}
                                             onChange={value => this.setState({email: value})}
                                             placeholder={"Email"}
                                             style={{marginBottom: '0'}}/>

                                <CustomInput value={this.state.phone}
                                             onChange={value => this.setState({phone: value})}
                                             placeholder={"Nr. de telefon"}
                                             style={{marginBottom: '0'}}/>


                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginTop: '10px'
                                }}>

                                    <Button onClick={() => {
                                        this.props.history.push("/events")
                                    }} className="add-button" style={{
                                        flex: 1,
                                        backgroundColor: "#00afcc",
                                        color: "white",
                                        marginRight: '5px'
                                    }}>
                                        Nu, mulțumesc!
                                    </Button>

                                    <Button onClick={() => {
                                        if (this.state.email.length > 0 || this.state.phone.length > 0) {
                                            this.saveGuestData()
                                        }
                                    }} className="add-button"
                                            style={{
                                                flex: 1,
                                                border: "1px solid #00afcc",
                                                backgroundColor: 'transparent',
                                                color: "#00afcc",
                                                marginLeft: '5px'
                                            }}>
                                        Confirmă
                                    </Button>

                                </div>
                            </div>
                        </Modal>


                    </div>

                </div>
            )
        }

        return (
            <div className={"Invitation-outer"} style={{overflowY: 'unset'}}>
                <div className="Invitation font-family-poppins-300"
                     style={this.state.inviteType === "Custom" ? style : {}}>
                    <Modal
                        classNames={{
                            overlay: 'modal-overlay',
                            modal: 'modal-invitation',
                            closeButton: 'modal-close-button',
                        }}
                        center
                        open={this.state.showModal}
                        onClose={() => {
                        }}
                        showCloseIcon={false}
                        closeOnOverlayClick={false}
                        closeOnEsc={false}
                    >
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <p style={{marginBottom: "20px"}}> Pt a beneficia de toate serviciile
                                Ality (profil, acces
                                rapid
                                la toate nunțile si evenimentele la care participați, alte
                                informații), trebuie să aveti
                                cont</p>

                            <div className="account-options">
                                <Button onClick={() => {
                                    this.props.history.push("/login")
                                }} className="add-button"
                                        style={{backgroundColor: "#00afcc", color: "white"}}>
                                    Intra in cont
                                </Button>

                            </div>

                            <div className={"account-options social-buttons"}>
                                <Button onClick={() => {
                                    this.props.history.push("/signup")
                                }} className="add-button"
                                        style={{backgroundColor: "#00afcc", color: "white"}}>
                                    Creeaza cont
                                </Button>

                                {
                                    window.flutter_inappwebview || window.alityAppView ?

                                        <div style={{flexDirection: 'column', width: '100%'}}>
                                            <button onClick={this.loginGoogle}
                                                    className={"social-login-button social-google-login"}
                                                    style={{width: '100%'}}>
                                                <Icon icon="google"/> Login Google
                                            </button>

                                            <button onClick={this.loginFacebook}
                                                    className={"social-login-button social-facebook-login"}
                                                    style={{width: '100%', marginTop: '10px'}}
                                            >
                                                <Icon icon="facebook"/> Login Facebook
                                            </button>

                                            {
                                                this.isIOS ?
                                                    <button onClick={this.loginApple}
                                                            className={"social-login-button social-apple-login"}
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '10px'
                                                            }}
                                                    >
                                                        <Icon icon="apple"/> Login Apple
                                                    </button>
                                                    : null
                                            }
                                        </div>
                                        :
                                        <>
                                            <GoogleWebLoginButton
                                                callbackFunction={(val) => this.googleSignIn(val)}
                                                style={{marginBottom: "5px"}}
                                            />

                                            <FacebookLogin
                                                appId="243653927956471"
                                                autoLoad={false}
                                                fields="name,email"
                                                //onClick={(item) => this.facebookSignIn(item)}
                                                callback={(item) => this.facebookSignIn(item)}
                                                //cookie
                                                //
                                                disableMobileRedirect={true}
                                                //isMobile={true}
                                                redirectUri={'https://app.ality.ro'}
                                                render={renderProps => (
                                                    <button
                                                        className={"social-login-button social-facebook-login"}
                                                        type={"button"}
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                    ><Icon icon="facebook"/> Autentificare cu
                                                        Facebook</button>
                                                )}
                                            />
                                        </>
                                }
                            </div>


                            <Button onClick={() => {
                                this.props.history.push("/events")
                            }} className="add-button"
                                    style={{backgroundColor: "#00afcc", color: "white"}}>
                                Nu, mulțumesc!
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        classNames={{
                            overlay: 'modal-overlay',
                            modal: 'modal-invitation',
                            closeButton: 'modal-close-button',
                        }}
                        center
                        open={this.state.showOptionalModal}
                        onClose={() => {
                        }}
                        showCloseIcon={false}
                        closeOnOverlayClick={false}
                        closeOnEsc={false}
                    >
                        Vă rugăm să completați adresa de email sau numărul de telefon.

                        <div style={{display: "flex", flexDirection: "column"}}>

                            <CustomInput value={this.state.email}
                                         onChange={value => this.setState({email: value})}
                                         placeholder={"Email"} style={{marginBottom: '0'}}/>

                            <CustomInput value={this.state.phone}
                                         onChange={value => this.setState({phone: value})}
                                         placeholder={"Nr. de telefon"}
                                         style={{marginBottom: '0'}}/>


                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                marginTop: '10px'
                            }}>

                                <Button onClick={() => {
                                    this.props.history.push("/events")
                                }} className="add-button" style={{
                                    flex: 1,
                                    backgroundColor: "#00afcc",
                                    color: "white",
                                    marginRight: '5px'
                                }}>
                                    Nu, mulțumesc!
                                </Button>

                                <Button onClick={() => {
                                    if (this.state.email.length > 0 || this.state.phone.length > 0) {
                                        this.saveGuestData()
                                    }
                                }} className="add-button"
                                        style={{
                                            flex: 1,
                                            border: "1px solid #00afcc",
                                            backgroundColor: 'transparent',
                                            color: "#00afcc",
                                            marginLeft: '5px'
                                        }}>
                                    Confirmă
                                </Button>

                            </div>
                        </div>
                    </Modal>

                    <div className="MainInvite-container">
                        {invite}
                    </div>

                    {/* <div className="calendar">
					<Button className="action" onClick={this.moveToEvent} color="green"> Deschide Evenimentul </Button>
				</div> */}

                    {/* <div className="Answer">
					<p style={{ fontSize: "20px" }}>Doriti sa participati?</p>

					<div className="Options">
						<div className="No" onClick={() => this.setAnswer("no")}>Nu Particip</div>

						<div className="Yes" onClick={() => this.setAnswer("yes")}>Particip</div>

						<div className="NotSure" onClick={() => this.setAnswer("don't know")}>Nu stiu</div>
					</div>
				</div> */}

                    {this.state.answer !== "" && <div className='Form'>
                        <div ref={this.state.topRef}></div>

                        <div style={{
                            textAlign: "left",
                            fontSize: "18px"
                        }}>{translateInviteTexts('Vă rugăm introduceți următoarele informații:', this.state.current_event.invite.language)}
                        </div>

                        <CustomInput value={this.state.prenume}
                                     onChange={value => this.setter("prenume", value)}
                                     placeholder={translateInviteTexts('Prenume, Nume', this.state.current_event.invite.language)}/>

                        {this.state.current_event.invite.hideEmailInput ? null :
                            <CustomInput value={this.state.email}
                                         onChange={value => this.setter("email", value)}
                                         style={{marginTop: 0}}
                                         placeholder={translateInviteTexts('Adresa de email', this.state.current_event.invite.language)}/>}

                        {/* <CustomInput value={this.state.nume} onChange={value => this.setter("nume", value)} placeholder={"Nume"} /> */}
                        {this.state.answer === "no" &&
                            <p>{translateInviteTexts('Ne pare rău să auzim asta, daca vă răzgândiți vă așteptam', this.state.current_event.invite.language)}</p>}
                        {this.state.answer === "don't know" &&
                            <p>{translateInviteTexts('Nu este obligatoriu să răspundeți acum, când sunteți sigur', this.state.current_event.invite.language)}</p>}

                        {this.state.answer === "yes" && <div>
                            <div className="data-row">
                                <div
                                    className={!this.state.insotit ? "active-option" : "inactive-option"}
                                    onClick={_ => this.setter("insotit", false)}>
                                    {translateInviteTexts('Vin singur/ă', this.state.current_event.invite.language)}
                                </div>

                                <div
                                    className={this.state.insotit ? "active-option" : "inactive-option"}
                                    onClick={_ => this.setter("insotit", true)}>
                                    {translateInviteTexts('Vin insoțit/ă', this.state.current_event.invite.language)}
                                </div>
                            </div>
                            {this.state.insotit && (
                                <CustomInput value={this.state.nume_insotitor}
                                             onChange={value => this.setter("nume_insotitor", value)}
                                             placeholder={translateInviteTexts("Prenume, Nume Insotitor", this.state.current_event.invite.language)}/>
                            )}

                            {
                                this.state.current_event.invite.hideChildrenQuestion ?
                                    null
                                    :
                                    this.state.current_event.eventType !== 'Petrecere' ?
                                        <>
                                            <div style={{
                                                fontSize: "18px",
                                                textAlign: "left"
                                            }}>{translateInviteTexts('Veniți însoțiți de copii?', this.state.current_event.invite.language)}</div>
                                            <div className="data-row">
                                                <div
                                                    className={!this.state.cuCopii ? "active-option" : "inactive-option"}
                                                    onClick={_ => this.setter("cuCopii", false)}>
                                                    {translateInviteTexts('Nu', this.state.current_event.invite.language)}
                                                </div>

                                                <div
                                                    className={this.state.cuCopii ? "active-option" : "inactive-option"}
                                                    onClick={_ => this.setter("cuCopii", true)}>
                                                    {translateInviteTexts('Da', this.state.current_event.invite.language)}
                                                </div>
                                            </div>
                                        </>
                                        : null
                            }
                            {this.state.cuCopii && (
                                <div>
                                    <div style={{
                                        fontSize: "18px",
                                        textAlign: "left"
                                    }}>{translateInviteTexts('Cu câți copii veniți?', this.state.current_event.invite.language)}</div>

                                    <div className="data-row">
                                        {this.state.kidsNumberOptions.map((nr, index) =>
                                            <div key={index}
                                                 className={this.state.nr_copii === nr ? "active-option" : "inactive-option"}
                                                 onClick={_ => this.setter("nr_copii", nr)}>
                                                {nr}
                                            </div>)}
                                    </div>
                                </div>
                            )}

                            {this.state.shouldUseCustomQuestions &&
                                <CustomQuestions questions={this.state.customQuestions}
                                                 answers={this.state.answers}
                                                 showTitle={true}
                                                 changeAnswers={this.changeAnswers}
                                                 intl={this.props.intl}/>}
                        </div>}

                        <br/>
                        <Button onClick={this.sendAnswer} className="add-button"
                                style={{
                                    backgroundColor: "#00afcc",
                                    color: "white",
                                    height: '60px'
                                }}>
                            {translateInviteTexts('Trimite răspuns', this.state.current_event.invite.language)}
                        </Button>

                        <div style={{marginTop: "50px", marginBottom: "50px", fontSize: "18px"}}>
                            {translateInviteTexts('Vă mulțumim!', this.state.current_event.invite.language)}
                        </div>
                    </div>}

                </div>

                <div><br/>
                    <Button className="action btn-back" onClick={() => this.props.history.go(-1)}
                            color="red"> Înapoi</Button>
                </div>
            </div>
        )
    }
}

export default injectIntl(MainInvite2);
