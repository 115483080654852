import React, {useEffect, useRef, useState} from "react";
import NavbarColor from "../../../components/NavbarColor";
import {useHistory, useLocation, useParams} from "react-router-dom";
import HeaderWhite from "../../../components/HeaderWhite";
import Loader from "../../components/Loader";
import queryString from 'query-string';
import "./Packages.scss"
import axios from "axios";
import {Button, Icon} from "rsuite";
import PackageCard from "./PackageCard/PackageCard";
import Modal from "react-responsive-modal";
import CustomInput from "../../components/custom/CustomInput";
import {toast} from "react-toastify";
import WalkthroughOverlay from "../../../components/walkthrough/WalkthroughtOverlay";
import WalkthroughOverlayBack from "../../../components/walkthrough/WalkthroughOverlayBack";
import Countdown from "react-countdown";
import moment from "moment-timezone";
import {BackButton} from "../../components/Helper";
import {ConfirmButton} from "../../../components/Helper";
import SVG_Petrecere from "../../assets/EventsTypes/petrecere-icon.svg";

export default function Packages() {

    const {id: eventID} = useParams()
    const history = useHistory()
    const queryParams = queryString.parse(useLocation().search)
    const netopiaForm = useRef()
    const topRef = useRef()

    const [event, setEvent] = useState(null)
    const [showAllAvailablePackages, setShowAllAvailablePackages] = useState(false)
    const [availablePackages, setAvailablePackages] = useState([])
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [addOnSelected, setAddOnSelected] = useState(null)
    const [addOnPrice, setAddOnPrice] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    const [showVoucherModal, setShowVoucherModal] = useState(false)
    const [voucherCode, setVoucherCode] = useState('')
    const [voucher, setVoucher] = useState(null)

    //Checkout form
    const [showCheckoutForm, setShowCheckoutForm] = useState(false)
    const [invoicingType, setInvoicingType] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyCui, setCompanyCui] = useState('')
    const [phone, setPhone] = useState('')
    const [invoicingCountry, setInvoicingCountry] = useState('Romania')
    const [invoicingCounty, setInvoicingCounty] = useState('')
    const [invoicingCity, setInvoicingCity] = useState('')
    const [invoicingAddress, setInvoicingAddress] = useState('')

    //Modals
    const [showTutorial, setShowTutorial] = useState(false)
    const [showTutorialBack, setShowTutorialBack] = useState(false)

    const [showDetailsModal, setShowDetailsModal] = useState(null)
    const [detailsModalId, setDetailsModalId] = useState(0)

    const [showOfferModal, setShowOfferModal] = useState(false)
    const [offerDate, setOfferDate] = useState(null)

    const [showPremiumUpgradeModal, setShowPremiumUpgradeModal] = useState(false)
    const [premiumCountdownDate, setPremiumCountdownDate] = useState(false)

    const [showUnlimitedUpgradeModal, setShowUnlimitedUpgradeModal] = useState(false)
    const [unlimitedCountdownDate, setUnlimitedCountdownDate] = useState(false)

    const [netopiaDetails, setNetopiaDetails] = useState(false)

    //Loaders
    const [initialLoading, setInitialLoading] = useState(true)
    const [showAddFreeAnimationModal, setShowAddFreeAnimationModal] = useState(false)

    const packagesDescriptions = {
        0: {
            descriptionLine1: "Valabilitate eveniment 6 luni",
            descriptionLine2: "Valabilitate invitație 48 de ore",
            descriptionLine3: "Vizualizarea a doar 5 răspunsuri primite de la invitați"
        },
        1: {
            descriptionLine1: "Valabilitate eveniment și invitație 18 luni",
            descriptionLine2: "Vizualizarea nelimitată a răspunsurilor primite de la invitați"
        },
        2: {
            descriptionLine1: "Valabilitate eveniment și invitație 18 luni",
            descriptionLine2: "Vizualizarea nelimitată a răspunsurilor primite de la invitați"
        },
        3: {
            descriptionLine1: "Crearea a până la maxim 2 invitații",
            descriptionLine2: "Invitații în limba română, engleză, franceză, spaniolă, italiană, germană sau maghiară."
        },
        4: {
            descriptionLine1: "Crearea a până la maxim 3 invitații",
            descriptionLine2: "Invitații în limba română, engleză, franceză, spaniolă, italiană, germană sau maghiară."
        },
        5: {
            descriptionLine1: "Valabilitate eveniment 18 luni",
            descriptionLine2: "Vizualizarea a maxim 25 de răspunsuri primite de la invitați"
        }
    }

    useEffect(() => {

        getData()
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let selectedPackageItem = getSelectedPackage()

        if (selectedPackageItem) {
            const currentPackageValue = getCurrentPackagePrice()
            const discountValue = getDiscountValue(selectedPackageItem.price + addOnPrice, [selectedPackageItem])
            let oldAddOnPrice = 0
            event.packages && event.packages.wedding_participants && event.packages.wedding_participants.details && event.packages.wedding_participants.details.addons &&
            event.packages.wedding_participants.details.addons.length > 0 &&
            event.packages.wedding_participants.details.addons.forEach(item => oldAddOnPrice += item.price)


            if (!(event && event.packages && event.packages.wedding_participants) || event.packages.wedding_participants.package !== selectedPackageItem.id || addOnPrice !== oldAddOnPrice) {
                setTotalPayment(selectedPackageItem.price + addOnPrice - currentPackageValue - discountValue)
            }
            else {
                setTotalPayment(0)
            }
        }


        // eslint-disable-next-line
    }, [selectedPackage, addOnPrice, voucher]);
    const getCurrentPackagePrice = () => {
        let currentPackage = getCurrentPackage()
        if (currentPackage && currentPackage.details && currentPackage.details.price) {

            let oldAddOnPrice= 0
            currentPackage.details && currentPackage.details.addons &&
            currentPackage.details.addons.length > 0 &&
            currentPackage.details.addons.forEach(item => oldAddOnPrice += item.price)
            return currentPackage.details.price + oldAddOnPrice
        } else {
            return 0
        }
    }
    useEffect(() => {
        if (netopiaDetails !== false) {
            document.getElementById('netopiaForm').submit();
            //netopiaForm.current.dispatchEvent(new Event("submit", {cancelable: true, bubbles: true}))
        }
    }, [netopiaDetails]);

    const getData = async () => {

        let showWalkthrough = true

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: eventID
        }

        //Get wedding data
        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + eventID, auth)
            .then(response => {

                showWalkthrough = !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.walkthrough)

                if (queryParams.noTutorial) {
                    showWalkthrough = false
                }

                if (response.data.packages !== null) {
                    let packagesData = response.data.packages
                    let participants_package = 0
                    if (packagesData.wedding_participants !== undefined) {
                        participants_package = packagesData.wedding_participants.package
                        setSelectedPackage(packagesData.wedding_participants.package ? packagesData.wedding_participants.package : null)
                        if (packagesData.wedding_participants.details && packagesData.wedding_participants.details.addons &&
                            packagesData.wedding_participants.details.addons.length > 0) {
                            setAddOnSelected(packagesData.wedding_participants.details.addons[0].id)
                            setAddOnPrice(packagesData.wedding_participants.details.addons[0].price)
                        }
                    }


                    //Check if is inv digitale nelim or inv digitale nelim
                    if (participants_package === 1 || participants_package === 3) {

                        let dateEnd = moment(new Date())
                        let eventDate = moment(new Date(response.data.dataEvent))


                        let remainingDays = eventDate.diff(dateEnd, 'days')

                        if (
                            response.data.eventType === 'Botez'
                            || response.data.eventType === 'Mot'
                            || response.data.eventType === 'Turta'
                            || response.data.eventType === 'Petrecere'
                        ) {
                            if (remainingDays <= 60 && remainingDays >= 57) {
                                setVoucherCode('PREMIUM15')
                                setPremiumCountdownDate(moment(new Date(response.data.dataEvent)).subtract(57, 'days'))
                                setShowPremiumUpgradeModal(true)
                                claimVoucher('PREMIUM15')
                            }
                        }

                        if (
                            response.data.eventType === 'Nunta'
                            || response.data.eventType === 'Cununie'
                        ) {
                            if (remainingDays <= 90 && remainingDays >= 87) {
                                setVoucherCode('PREMIUM15')
                                setPremiumCountdownDate(moment(new Date(response.data.dataEvent)).subtract(87, 'days'))
                                setShowPremiumUpgradeModal(true)
                                claimVoucher('PREMIUM15')
                            }
                        }
                    }

                    //Check upgrade to unlimited modal
                    if (participants_package === 5) {

                        let dateEnd = moment(new Date())
                        let eventDate = moment(new Date(response.data.dataEvent))


                        let remainingDays = eventDate.diff(dateEnd, 'days')


                        if (
                            response.data.eventType === 'Botez'
                            || response.data.eventType === 'Mot'
                            || response.data.eventType === 'Turta'
                            || response.data.eventType === 'Petrecere'
                        ) {
                            if (remainingDays <= 30 && remainingDays >= 27) {
                                setVoucherCode('NELIMITAT30')
                                setUnlimitedCountdownDate(moment(new Date(response.data.dataEvent)).subtract(27, 'days'))
                                setShowUnlimitedUpgradeModal(true)
                                claimVoucher('NELIMITAT30')
                            }
                        }

                        if (
                            response.data.eventType === 'Nunta'
                            || response.data.eventType === 'Cununie'
                        ) {
                            if (remainingDays <= 60 && remainingDays >= 57) {
                                setVoucherCode('NELIMITAT30')
                                setUnlimitedCountdownDate(moment(new Date(response.data.dataEvent)).subtract(57, 'days'))
                                setShowUnlimitedUpgradeModal(true)
                                claimVoucher('NELIMITAT30')
                            }
                        }
                    }
                } else {

                    let createdAtDate = moment(response.data.dataEvent)

                    let dateStart = moment(new Date()).subtract(9, 'days').utc().startOf('day').toDate()
                    let dateEnd = moment(new Date()).subtract(7, 'days').utc().endOf('day').toDate()

                    if (createdAtDate <= dateEnd && createdAtDate >= dateStart) {
                        setVoucherCode('10PFSTPCK')
                        claimVoucher('10PFSTPCK');
                    }
                }

                setEvent(response.data)
                setShowTutorial(showWalkthrough)
            })

        //Get available packages
        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/packages/getAvailablePackages', payload, auth)
            .then(response => {

                //Store invoicing details
                if (response.data.invoicingDetails) {
                }

                //Store available packages
                //sort them
                setAvailablePackages(response.data.wedding_participants.sort((a, b) => a.order > b.order ? 1 : -1))

                setOfferDate(new Date(response.data.offerUntil))
                setShowOfferModal(!showWalkthrough && !showPremiumUpgradeModal && !showUnlimitedUpgradeModal && (new Date().getTime() < new Date(response.data.offerUntil).getTime()))
                updateTutorial()

                setInitialLoading(false)
            })

        //Get user
        await axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
            .then(response => {
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setPhone(response.data.phone)
            })
    }

    const isCurrentPackage = (id) => {
        return event.packages && event.packages.wedding_participants && event.packages.wedding_participants.package === id
    }

    const getCurrentPackageOrder = () => {
        if (event.packages && event.packages.wedding_participants && event.packages.wedding_participants.package) {
            let currentPackage = availablePackages.find(item => item.id === event.packages.wedding_participants.package)
            return currentPackage.order
        }
        return 0
    }

    const claimVoucher = async (value = false) => {

        if (voucherCode.length > 0 || (value && value.length > 0)) {

            let payload = {
                code: value ? value : voucherCode,
                id_eveniment: eventID
            }

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            // assign package by code
            await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/claimDiscountCode', payload, auth)
                .then(response => {
                    if (response.data.status === 1) {

                        //if (this.state.participants_extra_packages_selected.length > 0
                        //    || this.state.facerecog_extra_packages_selected.length > 0) {
                        //    toast.error('Codul de reducere nu poate fi folosit la achiziționarea de pachete extra!')
                        //} else {
                        toast.success('Reducerea a fost adaugată!')

                        setVoucher(response.data.code)
                        setShowVoucherModal(false)
                        // }
                    } else {
                        toast.error('Codul nu este valid!')
                    }
                    //this.init()
                })
        }


    }

    const getCurrentPackage = () => {
        if (event.packages && event.packages.wedding_participants && event.packages.wedding_participants.details) {
            return event.packages.wedding_participants
        } else {
            return null
        }
    }

    const getSelectedPackage = () => {
        if (selectedPackage) {
            return availablePackages.find(item => item.id === selectedPackage)
        } else {
            return null
        }
    }


    const updateTutorial = async () => {

        setShowTutorial(false)

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + eventID,
            {
                packages: true
            },
            auth)

    }

    function renderCountdown({days, hours, minutes, seconds, completed}) {
        if (completed) {
            // Render a completed state
            return '';
        } else {
            // Render a countdown
            return <div className={"Package-countdown"}>
                <div className={"item"}>
                    <div className={"value"}>{days}</div>
                    <div className={"title"}>{days === 1 ? 'Zi' : 'Zile'}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}>{hours}</div>
                    <div className={"title"}>{hours === 1 ? 'Oră' : 'Ore'}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}>{minutes}</div>
                    <div className={"title"}>{minutes === 1 ? 'Minut' : 'Minute'}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}>{seconds}</div>
                    <div className={"title"}>{'Secunde'}</div>
                </div>

            </div>
        }
    }

    const getDiscountValue = (total) => {
        if (!voucher) {
            return 0
        }

        let selectedPackageItem = getSelectedPackage()
        const currentPackageValue = getCurrentPackagePrice()

        if (voucher.applies_on_total) {
            return voucher.percentage && voucher.percentage === true ?
                Math.ceil((total - currentPackageValue) * voucher.value / 100)
                :
                voucher.value
        } else {
            //If there is a wedding_participants package selected
            //and the discountCode is available for the selected package
            if (selectedPackageItem &&
                voucher.applies_on_packages.includes(selectedPackageItem.id)) {

                return voucher.percentage && voucher.percentage === true ?
                    Math.ceil((selectedPackageItem.price + addOnPrice - currentPackageValue) * voucher.value / 100)
                    :
                    voucher.value

            } else {
                return 0
            }
        }
    }

    const showCartContent = (goToPayment = true) => {

        let selectedPackageItem = getSelectedPackage()

        if (!selectedPackageItem) {
            return null
        }

        selectedPackageItem = {
            ...selectedPackageItem,
            type: "wedding_participants",
            nr: selectedPackageItem.id
        }


        let total = selectedPackageItem.price + addOnPrice;

        let oldAddOnPrice = 0
        event.packages && event.packages.wedding_participants && event.packages.wedding_participants.details && event.packages.wedding_participants.details.addons &&
        event.packages.wedding_participants.details.addons.length > 0 &&
        event.packages.wedding_participants.details.addons.forEach(item => oldAddOnPrice += item.price)

        const oldPackageDiscount = event.packages && event.packages.wedding_participants && event.packages.wedding_participants.details ?
            event.packages.wedding_participants.details.price + oldAddOnPrice : 0

        const discountValue = getDiscountValue(total, [selectedPackageItem])


        if (!selectedPackageItem) {
            return <div></div>
        } else {
            return <div style={{marginTop: "20px", margin: "10px 0"}}>
                <div className={"packages-voucher-cta"} style={{justifyContent: "center"}}>
                    <span onClick={() => setShowVoucherModal(true)}>
                        {voucher ?
                            <>Cod de reducere {voucher.code}</>
                            :
                            <>Ai un cod de reducere ?</>
                        }
                    </span>
                </div>
                <div style={{fontWeight: "bold"}}>Alegeri:</div>

                <div>
                    Pachet {selectedPackageItem.title} {addOnSelected && selectedPackageItem.addons.find(item => item.id === addOnSelected) ? '(+' + selectedPackageItem.addons.find(item => item.id === addOnSelected).title + ')' : ''},
                    preț: {selectedPackageItem.price + addOnPrice} Lei
                </div>


                {
                    oldPackageDiscount ?
                        <div>
                            Reducere pachet existent {oldPackageDiscount} Lei
                        </div>
                        : null
                }

                {
                    voucher && discountValue > 0 ?
                        <div>
                            Reducere {discountValue} Lei
                        </div>
                        : null
                }

                <div>
                    Total: {total - oldPackageDiscount - discountValue} lei
                </div>

                <div style={{textAlign:'center', padding: '1rem 1.5rem', backgroundColor:'#14499e'}}>
                    <img src={"/images/netopia.svg"} style={{width:'100%', maxHeight:'150px'}} alt={""} />
                </div>


                {goToPayment ? <>
                    <ConfirmButton
                        text={"Plătește"}
                        function={() => initPayment([selectedPackageItem], event.id)}
                    />
                    <br/><br/>

                    <form id={"netopiaForm"} method={"post"} ref={netopiaForm}
                          action={netopiaDetails ? netopiaDetails.redirectUrl : ''}>
                        {
                            netopiaDetails !== false ?
                                <>
                                    <input type={"hidden"} name={"env_key"}
                                           value={netopiaDetails.envKey}/>
                                    <input type={"hidden"} name={"data"}
                                           value={netopiaDetails.data}/>
                                </>
                                : null
                        }
                    </form>
                </> : null}
            </div>
        }
    }

    const initPayment = async (choices, weddingId) => {

        //Validare persoana fizica
        if (invoicingType === 0) {
            if (invoicingCountry.length === 0
                || invoicingCounty.length === 0
                || invoicingCity.length === 0
                || invoicingAddress.length === 0
                || !firstName
                || !lastName
                || firstName.length === 0
                || lastName.length === 0
            ) {
                toast.error('Vă rugăm completați numele, prenumele, țara, județul, orașul si adresa!')
                return
            }
        }

        //Validare persoana juridica
        if (invoicingType === 1) {
            if (invoicingCountry.length === 0
                || invoicingCounty.length === 0
                || invoicingCity.length === 0
                || invoicingAddress.length === 0
                || !companyName
                || !companyCui
                || companyName.length === 0
                || companyCui.length === 0
            ) {
                toast.error('Vă rugăm completați denumirea companiei, cui-ul, țara, județul, orașul si adresa!')
                return
            }
        }


        let selectedPackages = []

        choices.map(elem => {
            selectedPackages.push({
                type: "wedding_participants",
                nr: elem.id,
                addons: addOnSelected ? [addOnSelected] : []
            })
            return null
        })


        let payload = {
            packages: selectedPackages,
            weddingId: weddingId,
            invoicingData: {
                invoicing_type: invoicingType,
                country: invoicingCountry,
                county: invoicingCounty,
                city: invoicingCity,
                address: invoicingAddress,
                first_name: firstName,
                last_name: lastName,
                phone: phone,
                company_name: companyName,
                company_cui: companyCui
            },
            discountCode: voucher,

        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        // get packages data
        await axios.post(process.env.REACT_APP_DOMAIN + 'transactions/create', payload, auth)
            .then(response => {

                if (response.data.refreshScreen) {
                    this.init()
                }

                if (response.data.data) {
                    setNetopiaDetails(response.data)
                }
            })

    }


    const goBack = () => {
        history.push("/organizer/adminEventPage/" + eventID)
    }

    const customInputStyle = {
        margin: "10px 0px 15px 0",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return (<div className="Packages-container">
        <HeaderWhite shadow={true} goBack={goBack}/>

        {initialLoading ?
            <Loader/>
            :
            showCheckoutForm ?
                <div ref={topRef} className="Packages-contents">


                    <BackButton
                        text={'Înapoi la pachete'}
                        function={() => {
                            topRef.current.scroll({
                                top: 0
                            })
                            setShowCheckoutForm(false)
                        }}
                    />
                    <br/><br/>

                    <div className={"checkout-form"}>
                        <h5 style={{textAlign: 'center'}}>Vă rugăm introduceți datele de
                            facturare</h5>
                        <br/>

                        <div className={"invoicing-type-cont"}>
                            <div className={"item " + (invoicingType === 0 ? 'active' : '')}
                                 onClick={() => setInvoicingType(0)}>Persoană fizică
                            </div>
                            <div className={"item " + (invoicingType === 1 ? 'active' : '')}
                                 onClick={() => setInvoicingType(1)}>Persoană juridică
                            </div>
                        </div>

                        {
                            invoicingType === 0 ?
                                <>
                                    <CustomInput style={customInputStyle}
                                                 value={lastName}
                                                 onChange={(value) => setLastName(value)}
                                                 placeholder={'Nume'}/>

                                    <CustomInput style={customInputStyle}
                                                 value={firstName}
                                                 onChange={(value) => setFirstName(value)}
                                                 placeholder={'Prenume'}/>
                                </>
                                :
                                <>
                                    <CustomInput key={3} style={customInputStyle}
                                                 value={companyName}
                                                 onChange={(value) => setCompanyName(value)}
                                                 placeholder={'Denumirea societății'}/>

                                    <CustomInput key={4} style={customInputStyle}
                                                 value={companyCui}
                                                 onChange={(value) => setCompanyCui(value)}
                                                 placeholder={'CUI'}/>
                                </>
                        }


                        <CustomInput style={customInputStyle}
                                     value={phone}
                                     onChange={(value) => setPhone(value)}
                                     placeholder={'Telefon'}/>

                        <CustomInput style={customInputStyle}
                                     value={invoicingCountry}
                                     onChange={(value) => setInvoicingCountry(value)}
                                     placeholder={'Țara'}/>

                        <CustomInput style={customInputStyle}
                                     value={invoicingCounty}
                                     onChange={(value) => setInvoicingCounty(value)}
                                     placeholder={'Județ'}/>

                        <CustomInput style={customInputStyle}
                                     value={invoicingCity}
                                     onChange={(value) => setInvoicingCity(value)}
                                     placeholder={'Oraș'}/>

                        <CustomInput style={customInputStyle}
                                     value={invoicingAddress}
                                     onChange={(value) => setInvoicingAddress(value)}
                                     placeholder={'Adresa'}/>


                        {showCartContent()}


                    </div>

                </div>
                :
                <div ref={topRef} className="Packages-contents">
                    <div className={"packages-voucher-cta"}>
                    <span onClick={() => setShowVoucherModal(true)}>
                        {voucher ?
                            <>Cod de reducere {voucher.code}</>
                            :
                            <>Ai un cod de reducere ?</>
                        }
                    </span>
                    </div>

                    <div className={"packages-cont"}>
                        {availablePackages.map((availablePackage, i) => (
                            <PackageCard
                                key={i}
                                event={event}
                                packageItem={availablePackage}
                                isCurrentPackage={isCurrentPackage(availablePackage.id)}
                                currentPackageOrder={getCurrentPackageOrder()}
                                currentPackage={getCurrentPackage()}
                                showAllAvailablePackages={showAllAvailablePackages}
                                selectedPackage={selectedPackage}
                                setSelectedPackage={setSelectedPackage}
                                packagesDescriptions={packagesDescriptions}
                                voucher={voucher}
                                showDetailsModal={() => {
                                    setDetailsModalId(availablePackage.id)
                                    setShowDetailsModal(true)
                                }}
                                addOnSelected={addOnSelected}
                                addOnPrice={addOnPrice}
                             //   addOnSelected={availablePackage && selectedPackage === availablePackage.id ? addOnSelected : null}
                                setAddOnSelected={setAddOnSelected}
                               // addOnPrice={availablePackage && selectedPackage === availablePackage.id ? addOnPrice : 0}
                                setAddOnPrice={setAddOnPrice}
                                setShowAddFreeAnimationModal={setShowAddFreeAnimationModal}
                            />
                        ))}
                    </div>
                    {!showAllAvailablePackages && getCurrentPackageOrder() > 0 ?
                        <div className={"show-all-packages"}
                             onClick={() => setShowAllAvailablePackages(true)}>Afișează
                            toate pachetele</div>
                        :
                        <div className={"show-all-packages"}
                             onClick={() => setShowAllAvailablePackages(false)}>Afișează
                            doar pachetele disponibile</div>}


                    {selectedPackage && (totalPayment > 0) ?
                        <div className={"go-to-checkout-button"} onClick={() => {
                            topRef.current.scroll({
                                top: 0
                            })
                            setShowCheckoutForm(true)
                        }}>
                            Mergi la plată ({totalPayment} lei)<Icon icon={"right"}/>
                        </div> : null}
                </div>

        }

        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }}
            center
            open={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
            focusTrapped={false}
        >
            {
                detailsModalId === 0 ?
                    <>
                        <br/>
                        <h3>Gratuit</h3>
                        <div className={"item"}>
                            <Icon icon="check"/> Crearea evenimentului și introducerea detaliilor
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Valabilitate eveniment 6 luni
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Valabilitate invitație 48 de ore
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Adăugarea și integrarea adreselor cu Waze și Google
                            Maps
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Alegerea invitației din template-urile existente
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Posibilitatea de a crea o invitației de la zero
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Încărcarea unei invitații foto sau video deja
                            create
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Adăugarea de întrebări personalizate pe invitație
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Trimiterea invitațiilor pe Whatsapp, Facebook sau
                            E-mail
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Vizualizarea a doar 5 răspunsuri primite de la
                            invitați
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Parcurgerea de task-uri personalizate
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Gestionarea bugetului și centralizarea
                            cheltuielilor
                        </div>
                        <div className={"item"}>
                            <Icon icon="check"/> Crearea programului din ziua evenimentului
                        </div>


                    </>
                    :
                    detailsModalId === 1 ?
                        <>
                            <br/>
                            <h3>Invitație cu răspunsuri nelimitate</h3>
                            <div className={"item"}>
                                <Icon icon="check"/> Vizualizarea nelimitată a răspunsurilor primite
                                de la invitați
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Valabilitate eveniment și invitație 18 luni
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Centralizarea răspunsurilor primite de la
                                invitați
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Integrarea în invitație a locațiilor cu Waze /
                                Google maps
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Posibilitatea de a exporta invitația în format
                                png
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Posibilitatea de a adăuga manual invitați
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Adăugarea unei melodii care va rula la
                                deschiderea
                                invitației
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Înregistrarea unui mesaj audio personalizat pe
                                invitație
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Posibilitatea de a adăuga invitații dintr-un
                                document Excel
                            </div>
                            <div className={"item"}>
                                <Icon icon="check"/> Filtrarea răspunsurilor după anumite criterii
                            </div>


                        </>
                        :
                        detailsModalId === 2 ?
                            <>
                                <br/>
                                <h3>Premium</h3>
                                <div className={"item"}>
                                    <Icon icon="check"/> Vizualizarea nelimitată a răspunsurilor
                                    primite de la invitați
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Valabilitate eveniment și invitație 18 luni
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Creare planului locației cu poziția
                                    meselelor
                                    și a
                                    punctelor de interes
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Integrarea în invitație a locațiilor cu
                                    Waze / Google maps
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Aranjarea invitaților la mese
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Notificarea invitațiilor în cazul unor
                                    schimbări de program
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Participanții vor putea accesa evenimentul
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Adăugarea mai multor organizatori
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Distribuirea albumelor media către
                                    participanți
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Crearea și publicarea listei de cadouri
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Informarea organizatorilor cu privirea la
                                    numărul invitaților
                                    prezenți la eveniment
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Funcția „Smart Check-in” pentru primirea
                                    invitaților
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Posibilitatea de a exporta toate
                                    informațiile din aplicație
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Alegerea hostesselor din lista de invitați
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Invitarea hostesselor din exterior
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Introducerea darului primit de la fiecare
                                    invitat
                                </div>
                                <div className={"item"}>
                                    <Icon icon="check"/> Publicarea evenimentului în secțiunea
                                    evenimentelor private
                                </div>
                            </>
                            : detailsModalId === 3 ?
                                <>
                                    <br/>
                                    <h3>Invitații digitale multilanguage</h3>
                                    <div className={"item"}>
                                        <Icon icon="check"/> Crearea a până la maxim 2 invitații
                                    </div>
                                    <div className={"item"}>
                                        <Icon icon="check"/> Valabilitate eveniment și invitație 18 luni
                                    </div>
                                    <div className={"item"}>
                                        <Icon icon="check"/> Invitații în limba română, engleză,
                                        franceză, spaniolă,
                                        italiană, germană sau maghiară.
                                    </div>
                                </>
                                : detailsModalId === 4 ?
                                    <>
                                        <br/>
                                        <h3>Premium multilanguage</h3>
                                        <div className={"item"}>
                                            <Icon icon="check"/> Crearea a până la maxim 3 invitații
                                        </div>
                                        <div className={"item"}>
                                            <Icon icon="check"/> Valabilitate eveniment și invitație 18
                                            luni
                                        </div>
                                        <div className={"item"}>
                                            <Icon icon="check"/> Invitații în limba română, engleză,
                                            franceză, spaniolă,
                                            italiană, germană sau maghiară.
                                        </div>
                                    </>
                                    : detailsModalId === 5 ?
                                        <>
                                            <br/>
                                            <h3>Invitație cu 25 de răspunsuri</h3>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Vizualizarea a maxim 25 de
                                                răspunsuri
                                                primite de la invitați
                                            </div>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Valabilitate eveniment și invitație
                                                18
                                                luni
                                            </div>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Centralizarea răspunsurilor primite
                                                de
                                                la invitați
                                            </div>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Integrarea în invitație a
                                                locațiilor cu Waze / Google
                                                maps
                                            </div>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Posibilitatea de a adăuga manual
                                                invitați
                                            </div>


                                            <div className={"item"}>
                                                <Icon icon="check"/> Adăugarea unei melodii care va rula
                                                la
                                                deschiderea
                                                invitației
                                            </div>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Înregistrarea unui mesaj audio
                                                personalizat pe
                                                invitație
                                            </div>


                                            <div className={"item"}>
                                                <Icon icon="check"/> Posibilitatea de a adăuga invitații
                                                dintr-un
                                                document Excel
                                            </div>
                                            <div className={"item"}>
                                                <Icon icon="check"/> Filtrarea răspunsurilor după
                                                anumite
                                                criterii
                                            </div>

                                        </>
                                        : null
            }
        </Modal>

        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }}
            center
            open={showVoucherModal}
            onClose={() => setShowVoucherModal(false)}
            focusTrapped={false}
        >
            <div style={{marginTop: "20px"}}>
                <h4>Introduceți codul</h4>

                <CustomInput
                    value={voucherCode}
                    onChange={(value) => setVoucherCode(value)}
                    placeholder={"Cod"}
                />

                <Button
                    onClick={() => claimVoucher()}
                    color="green"
                    style={{
                        backgroundColor: "#05AFF2",
                        borderRadius: "20px",
                        width: "100%"
                    }}>Revendică</Button>
            </div>
        </Modal>


        {
            showTutorial ?
                <WalkthroughOverlay id_eveniment={eventID} history={history}>
                    <p>La crearea evenimentului beneficiezi în mod automat de pachetul GRATUIT!
                        Pentru a beneficia
                        de
                        toate funcționalitățile aplicației, trebuie să alegi unul dintre pachetele
                        disponibile.</p>

                    <div className={"next-button"} onClick={() => {
                        setShowTutorial(false)
                        setShowTutorialBack(true)
                    }}>Mai departe
                    </div>
                </WalkthroughOverlay>
                : null
        }

        {
            showTutorialBack ?
                <WalkthroughOverlayBack
                    action={() => history.push('/organizer/viewEvents?finishWalkthrough=' + eventID)}
                />
                : null
        }


        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-profile',
                closeButton: 'modal-close-button',
            }}
            open={showOfferModal}
            onClose={() => setShowOfferModal(false)}
            focusTrapped={false}
        >
            <div style={{marginTop: "20px", marginBottom: "30px"}}>
                <h3 style={{color: '#D82148'}}>Grăbește-te!</h3>
                <h5 style={{color: 'green'}}>Promoție -30%</h5>
                <h6 style={{marginTop: '30px'}}>Au mai rămas doar</h6>

                <Countdown
                    date={offerDate}
                    renderer={renderCountdown}
                />

                <div className={"package-offer-description"}>

                    {/*eslint-disable-next-line*/}
                    ✨ Organizarea unui eveniment nu a fost niciodată mai ușoară și eficientă!
                    Planificarea unui întreg
                    eveniment poate fi complicată, dar și o sarcina care necesita mult timp și
                    atenție din partea dvs.
                    <br/><br/>
                    Cu ocazia Sărbătorilor Pascale, 🐰 suntem încântați să vă oferim un pachet
                    special din cadrul
                    serviciile noastre!
                    <br/><br/>
                    În perioada <span style={{color: 'red'}}>1-7 mai</span>, vă oferim discount
                    substantial la produsele
                    noastre, cu reduceri de până la <span style={{color: 'red'}}>30%</span> în
                    funcție de pachetul
                    ales. <span style={{textDecoration: 'underline'}}>Atentie, reducerea nu se cumulează cu alte coduri de reducere.</span>
                    <br/><br/>
                    Ne dorim să vă oferim cea mai bună experiență posibilă cu Ality, și sperăm că
                    această ofertă
                    specială de Paște vă va aduce bucurie și confort în planificarea evenimentelor
                    dumneavoastră!✨

                </div>

                <div>
                </div>

                <Button
                    onClick={() => {
                        setShowOfferModal(false)
                    }}
                    color="green"
                    style={{
                        backgroundColor: "#05AFF2",
                        borderRadius: "20px",
                        width: "100%"
                    }}>Ok</Button>
            </div>
        </Modal>

        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }}
            center
            open={showPremiumUpgradeModal}
            onClose={() => setShowPremiumUpgradeModal}
            focusTrapped={false}
        >
            <div style={{marginTop: "20px"}}>
                <h4>Ofertă pachet premium</h4>

                {
                    premiumCountdownDate ?
                        <Countdown
                            date={premiumCountdownDate.toDate()}
                            renderer={renderCountdown}
                        />
                        : null}

                <p>Am pregătit pentru tine o ofertă specială.
                    Beneficiezi de o reducere de 15% la achiziționarea pachetului Premium / Premium
                    Multilanguage,
                    astfel, poți face un upgrade la un preț avantajos și vei avea parte de toate
                    avantajele pachetului
                    Premium / Premium Multilanguage.
                    <br/><br/>
                    Ceea ce primești cu achiziționarea pachetul Premium / Premium Multilanguage
                    depășește cu mult
                    beneficiile pachetului Invitație cu răspunsuri nelimitate .
                    Vrem ca tu să ai parte de o experiență memorabilă și ne-am asigurat că acest
                    pachet te va ajuta să
                    îți realizezi visul cu ușurință și eleganță.

                </p><br/>
                <Button
                    onClick={() => setShowPremiumUpgradeModal(false)}
                    color="green"
                    style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%"}}>Am
                    înțeles</Button>
            </div>
        </Modal>

        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }}
            center
            open={showUnlimitedUpgradeModal}
            onClose={() => setShowUnlimitedUpgradeModal(false)}
            focusTrapped={false}
        >
            <div style={{marginTop: "20px"}}>
                <h4>Ofertă pachet nelimitat</h4>

                {
                    unlimitedCountdownDate ?
                        <Countdown
                            date={unlimitedCountdownDate.toDate()}
                            renderer={renderCountdown}
                        />
                        : null}

                <p>Am pregătit pentru tine o ofertă specială.
                    Beneficiezi acum de o reducere de 30% la achiziționarea upgrade-ului pentru
                    pachetul de Invitație cu
                    răspunsuri nelimitate, disponibil la un preț avantajos de doar 81 de lei fata de
                    117 lei.
                    <br/><br/>
                    La pachetul Invitație cu răspunsuri nelimitate avantajele sunt: posibilitatea de
                    a exporta invitația
                    sub forma PNG, si vizualizarea nelimitată a răspunsurilor primite de la invitați
                    in pachetului
                    Invitații Digitale Nelimitate.
                    <br/><br/>
                    Vrem ca tu să ai parte de o experiență memorabilă și ne-am asigurat că acest
                    pachet te va ajuta să
                    îți realizezi visul cu ușurință și eleganță.
                </p><br/>
                <Button
                    onClick={() => setShowUnlimitedUpgradeModal(false)}
                    color="green"
                    style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%"}}>Am
                    înțeles</Button>
            </div>
        </Modal>

        <NavbarColor history={history}/>

        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }}
            center
            open={showAddFreeAnimationModal}
            onClose={() => {
                getData()
                setShowAddFreeAnimationModal(false)
            }}
            focusTrapped={false}
        >
            <div style={{marginTop: "20px"}}>
                <div className='Circle'><img src={SVG_Petrecere} alt="Participanti"
                                             style={{height: '100px', margin: "auto", display: "flex"}}/></div>
                <h6 style={{marginTop: '20px', textAlign: "center"}}>Felicitări! Ați primit pachetul invitație animată gratuit!</h6>

                <p style={{marginTop: '8px', textAlign: "center"}}>Acum puteți merge în secțiunea “Editează invitația” pentru a va crea invitația mult dorită cu animații.</p>
                <Button
                    onClick={() =>
                       history.push('/organizer/editInvite/' +event.id_eveniment)}
                    color="green"
                    style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop: '20px'}}>Mergi la editează invitația</Button>
            </div>
        </Modal>


    </div>)
}
