import React, {useEffect, useState} from "react";
import NavbarColor from "../../../components/NavbarColor";
import {useHistory, useParams} from "react-router-dom";
import HeaderWhite from "../../../components/HeaderWhite";
import Loader from "../../../components/Loader";
import "./styles.scss"
import axios from "axios";
import {getWeddingParticipantsPackageNumber} from "../../../functions";
import {BackAndConfirmButtons, ConfirmButton} from "../../../organizer/components/Helper";
import {toast} from "react-toastify";
import fileDownload from "js-file-download";
import {BackButton} from "../../../organizer/components/Helper";
import {useIntl} from "react-intl";

export default function WeddingParticipantsPhotos() {

    const intl = useIntl();
    const {id: eventID} = useParams()
    const history = useHistory()
    const [event, setEvent] = useState(null)
    const [driveUrl, setDriveUrl] = useState('')

    //Loaders
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, []);


    const getData = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        //Get wedding data
        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + eventID, auth)
            .then(response => {
                setEvent(response.data)
            })
            .catch(err => {
                toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
            })

        //Get drive link
        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/participants-photos/getDriveLink/' + eventID, auth)
            .then(response => {
                if (response.data && response.data.data && response.data.data.drive_url) {
                    setDriveUrl(response.data.data.drive_url)
                }
                setInitialLoading(false)
            })
            .catch(err => {
                setInitialLoading(false)
            })
    }


    const downloadImage = (url) => {

        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('download_image', 'down_img', url).then(res => {
                if (res === "fail") {
                    toast.error("Descarcare esuata")
                } else {
                    toast.success("Imagine descarcata")
                }
            })
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'downloadMedia',
                url: url,
            }))
        } else {
            var parts = url.split("/")
            var name = 'qr_' + parts[parts.length - 2] + '.jpg'

            axios.get(url, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, name)
                })
        }
    }

    const openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    const goBack = () => {
        history.push("/organizer/adminEventPage/" + eventID)
    }

    return (<div className="WeddingParticipantsPhotos-container">
        <HeaderWhite shadow={true} goBack={goBack}/>

        {initialLoading ?
            <Loader/>
            :
            <div className="WeddingParticipantsPhotos-contents">

                {getWeddingParticipantsPackageNumber(event) !== 2 && getWeddingParticipantsPackageNumber(event) !== 4 ?
                    <div>
                        <br/>
                        <h4 style={{textAlign: 'center'}}> Pentru a accesa această opțiune trebuie să achiziționați unul
                            dintre pachetele PREMIUM.</h4>
                        <br/>
                        <p>
                            <ConfirmButton
                                style={{width: '100%', marginTop: '30px'}}
                                text={"Achiziționează pachet PREMIUM"}
                                function={() => {
                                    history.push('/organizer/package/' + eventID)
                                }}
                            />
                        </p>
                    </div>
                    :
                    <div>
                        <br/>
                        <img
                            src={process.env.REACT_APP_DOMAIN + 'weddings/participants-photos/getQrCode/' + eventID + '/0'}
                            alt={""} style={{width: '100%'}}/>

                        {window.alityAppView ?
                            <BackAndConfirmButtons
                                outerStyle={{width: '100%', margin: '20px 0 0 0'}}
                                textBack={"Deschide"}
                                textConfirm={"Descarcă"}

                                functionBack={() => openLink(process.env.REACT_APP_DOMAIN + 'weddings/participants-photos/getQrCode/' + eventID + '/0')}
                                functionConfirm={() => {
                                    downloadImage(process.env.REACT_APP_DOMAIN + 'weddings/participants-photos/getQrCode/' + eventID + '/0')
                                }}
                            />
                            :
                            <ConfirmButton
                                style={{width: '100%', marginTop: '20px'}}
                                text={"Descarcă QR"}
                                function={() => {
                                    downloadImage(process.env.REACT_APP_DOMAIN + 'weddings/participants-photos/getQrCode/' + eventID + '/0')
                                }}
                            />}

                        {driveUrl && driveUrl.length > 0 ?
                            <BackButton
                                style={{width: '100%', margin: '10px 0 0 0'}}
                                text={"Vezi fotografiile încărcate"}
                                function={() => {
                                    openLink(driveUrl)
                                }}
                            /> : null}
                    </div>
                }
            </div>
        }


        <NavbarColor history={history}/>


    </div>)
}
